<template>
    <div id="main" onload="getBackgroundImage();">
        <div id="phone" class="contacts"  style="left: 60px; top: 36px;">
            <div class="icon callIcon">
            </div>
            <div class="text">
                + 7 (800) 555-55-55
            </div>
        </div>
        <div id="post" class="contacts" style="left: 324px; top: 36px;">
            <div class="icon emeilIcon">
            </div>
            <div class="text">
                info@bingosoft.ru
            </div>
        </div>
        <div id="title">
            <div id="big_text" class="text_title" style="top: 144px; left: 60px; position: absolute;">
                Добро пожаловать
            </div>
            <div id="small_text" class="text28" style="top: 248px; left:60px; position: absolute;">
                Выберите пункт в меню слева, чтобы начать работу
            </div>
        </div>
        <div id="docs">
            <div id="docs_column_1" class="docs_column">
                <div class="doc_item">
                    <a href="">
                        <div class="doc_icon">
                        </div>
                        <div class="doc_text">
                            Инструкция по работе в системе
                        </div>
                    </a>
                </div>
                <!-- <div class="doc_item">
                    <a href="/homepage/Pochta/Формирование инвестзаявок.docx">
                        <div class="doc_icon">
                        </div>
                        <div class="doc_text">
                            Инструкция по формированию заявок
                        </div>
                    </a>
                </div> -->
            </div>
            <!-- <div id="docs_column_2" class="docs_column">
                <div class="doc_item">
                    <a href="/homepage/Pochta/Руководство по формированию корректировки проекта.docx">
                        <div class="doc_icon">
                        </div>
                        <div class="doc_text">
                            Руководство по формированию корректировки проекта
                        </div>
                    </a>
                </div>
                <div class="doc_item" style="display: none;">
                    <a href="/homepage/empty_file.docx">
                        <div class="doc_icon">
                        </div>
                        <div class="doc_text">
                            Руководство по формированию отчетности
                        </div>
                    </a>
                </div>
            </div> -->
        </div>
        <div class="logo_frame">
        </div>
        <div id="footer">

            <div id="footer_text" class="text28" style="margin-bottom: 20px;">
                Автоматизированная система согласования земельных работ
            </div>
            <div id="footer_images" style="position:inherit; right:0px;">
                <div id="image_1_box">
                    <div id="image_1" class="image_1">
                    </div>
                </div>
                <!-- <div id="stick" class="stick">
                </div> -->
                <!-- <div id="image_2" class="image_2">
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'WelcomePage',
  mounted () {
    document.getElementById('main').classList.add('background_' + (1 + Math.floor(Math.random() * Math.floor(1))))
  }
}
</script>

<style scoped src="./Welcome.css">
</style>
